/* Vendor imports */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
/* App imports */
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Utils from '../../utils'
import style from './index.module.less'


export const aboutPropTypes = {
  data: PropTypes.shape({
    profilePhoto: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    flagIt: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fixed: PropTypes.object.isRequired,
      }),
    }),
    flagEn: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fixed: PropTypes.object.isRequired,
      }),
    }),
    skillIcons: PropTypes.object.isRequired,
    toolIcons: PropTypes.object.isRequired,
  }),
}

class About extends React.Component {
  static propTypes = aboutPropTypes

  render() {
    let { profilePhoto, flagIt, skillIcons, toolIcons } = this.props.data
    return (
      <Layout>
        <SEO
          title='About'
          description='A brief summary of this blog'
          path='about'
        />
        <div className={style.container} >
          <div className={style.photo}>
            <Img style={{ borderRadius: '50%' }} fluid={profilePhoto.childImageSharp.fluid} />
          </div>
          <div className={style.content}>
            <h1>Hi, I'm Abdullah AL Habib!</h1>
            <h2>Software Engineer</h2>
            {/*<a href={Utils.resolvePageUrl('../', 'it', 'about')}>*/}
            {/*  <Img*/}
            {/*    fixed={flagIt.childImageSharp.fixed}*/}
            {/*    style={{ display: 'block', margin: 'auto' }}*/}
            {/*  />*/}
            {/*</a>*/}
            <p>
              I am a Curious Backend Developer with great knowledge of Competitive Programming. I love to solve
              real-life problems.</p><p>
            Happy to work with the latest cutting-edge technologies. I have done more than 8 projects using Java -
            Spring boot,
            PostgreSQL. I also worked at other frameworks like Express, ReactJs. Also, know about Spring-Security, JWT,
            Azure, AWS, Jenkins, OOP, Design Patterns.</p><p>
            I am also familiar with Docker, Nginx, microservice, load balancing, flutter-Dart.</p><p>


            I am a good team player, can work under pressure, can take challenges in a new environment. And happy to
            learn new technologies.
          </p>
            <br />

            {/*<h2>Skills</h2>*/}
            {/*<ImageList edges={skillIcons.edges} />*/}
            {/*<h2>Tools</h2>*/}
            {/*<ImageList edges={toolIcons.edges} />*/}
          </div>
        </div>
      </Layout>
    )
  }
}

export const imageListPropTypes = {
  edges: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        name: PropTypes.string.isRequired,
        childImageSharp: PropTypes.shape({
          fixed: PropTypes.object.isRequired,
        }).isRequired,
      }).isRequired,
    }),
  ).isRequired,
}

class ImageList extends React.Component {
  static propTypes = imageListPropTypes

  render = () => (
    <div className={style.iconsContainer}>
      {this.props.edges
        .sort((edgeA, edgeB) =>
          edgeA.node.name.toLowerCase() > edgeB.node.name.toLowerCase() ? 1 : -1,
        )
        .map(({ node: { name, childImageSharp } }) => (
          <div className={style.iconWrapper} key={name}>
            <Img
              fixed={childImageSharp.fixed}
              alt={name + ' logo'}
              title={name}
            />
            <label>
              {iconsNameMap[name] ? iconsNameMap[name] : Utils.capitalize(name)}
            </label>
          </div>
        ))}
    </div>
  )
}

export const query = graphql`
  {
    profilePhoto: file(name: { eq: "profile" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    flagIt: file(name: { eq: "flag-it" }) {
      childImageSharp {
        fixed(width: 50) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    skillIcons: allFile(filter: { dir: { regex: "/about/skills$/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fixed(width: 50) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    }
    toolIcons: allFile(filter: { dir: { regex: "/about/tools$/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fixed(width: 50) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    }
  }
`
// Use to set specific icons names
export const iconsNameMap = {
  css: 'CSS',
  html: 'HTML',
  jquery: 'JQuery',
  nodejs: 'Node.js',
  vuejs: 'Vue.js',
  gruntjs: 'Grunt.js',
}

export default About
